@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR:wght@100;200;300;400;500;600;700&family=PT+Sans:wght@700&display=swap');

body {
  font-family: 'IBM Plex Sans KR', sans-serif !important;
}

:root {
  --vh: 100%;
}

html,
body, #main {
  min-height: var(--vh);
  height: 100%;
}

#image-grid-wrapper img:hover {
  border: 4px solid purple;
}

#image-grid-wrapper img {
  cursor: pointer;
}

.color-selected {
  outline: gray solid 3px !important;
}

.react-datepicker__input-container::before {
  content: '\e99c';
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 7px;
  font-family: icomoon !important;
  font-size: 1rem;
  color: #333;
}